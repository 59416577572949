define('gemini/controllers/app/info', ['exports', 'ember-i18n', 'gemini/config/environment'], function (exports, _emberI18n, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ref: Ember.inject.service('firebase'),
        i18n: Ember.inject.service(),

        uploadUrl: _environment.default.backEndUrl + "file-upload",

        infotitle: null,
        infotextwall: null,
        infowallimage: null,
        infowallimagewidth: null,
        infowallimageheight: null,
        infotext: null,
        info: null,

        "image-uploads": [],

        wallimageloaded: false,

        showWall: _environment.default.modules.infoShort === true,
        showField: _environment.default.modules.infoShort ? "wall" : "information",
        feedback: _environment.default.modules.feedback,

        actions: {
            saveText: function saveText() {

                this.set("is-saving", true);

                var uploadPromises = [];
                this.get('image-uploads').forEach(function (imageUpload) {
                    uploadPromises.push(imageUpload.upload());
                });
                var _this = this;
                var ref = _this.get('ref');
                Ember.RSVP.Promise.all(uploadPromises).then(function () {
                    ref.child('info/wall/about/title').set(_this.get('infotitle'));
                    ref.child('info/wall/about/text').set(_this.get('infotextwall'));
                    ref.child('info/wall/about/image-url').set(_this.get('infowallimage'));
                    ref.child('info/wall/about/image-width').set(_this.get('infowallimagewidth'));
                    ref.child('info/wall/about/image-height').set(_this.get('infowallimageheight'));

                    _this.set("is-saving", false);
                });
            },

            setField: function setField(field) {
                this.set("showField", field);
            }
        },
        saveUpdateListener: Ember.observer("is-saving", function () {
            if (this.get("is-saving")) {
                this.set("saveUpdate", (0, _emberI18n.translationMacro)("saving"));
            } else {
                this.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
                var _this = this;
                Ember.run.later(function () {
                    _this.set('saveUpdate', '');
                }, 5000);
            }
        }),

        init: function init() {
            var _this = this;
            var ref = this.get('ref');

            console.log("showwall: ", this.showWall);

            ref.child('info/wall/about').on("value", function (snapshot) {
                _this.setProperties({ infotextwall: snapshot.child('text').val() });
                _this.setProperties({ infotitle: snapshot.child('title').val() });
                _this.setProperties({ infowallimage: snapshot.child('image-url').val() });
                _this.setProperties({ infowallimagewidth: snapshot.child('image-width').val() });
                _this.setProperties({ infowallimageheight: snapshot.child('image-height').val() });
            });
        }
    });
});