define('gemini/controllers/app/files/item', ['exports', 'gemini/controllers/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saveUpdate: "",

    uploadUrl: _environment.default.backEndUrl + "file-upload",
    "upload-state": "waiting",
    response: null,

    "file-name": Ember.computed('model', function () {
      return this.get("model.id");
    }),

    actions: {
      save: function save() {
        var _this = this;

        this.set('upload-state', 'upload');

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('saveUpdate', 'Opgeslagen!');
          _this.set('upload-state', 'ready');
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('app.files');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    },

    responseListener: Ember.observer('response', function () {
      var model = this.get('model');
      var _this = this;
      model.save().then(function () {
        _this.set('saveUpdate', 'Opgeslagen!');
      });
    })
  });
});